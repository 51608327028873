// .<i class="arrow down"></i>
// .mat-form-field-flex{
//   background-color: white;
// }
// .mat-form-field-flex{
//   background-color: $white;
//   &:hover{
//     border: $secundary-color;
//   }
// }
.custom-select::after {
  display: none;
  // content: "\f106"!important
}
  .arrow{
  border: solid $secundary-color;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  &.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  &.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}

fieldset{
  margin:10px auto;
  select {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    -ms-progress-appearance: none;
    -o-appearance: none;
  }
  select + i {
    float: right;
    pointer-events: none;
    margin-top: -20px;
    margin-right: 15px;
  }
  .custom-checkbox .custom-control-input:checked~.custom-control-label::after{
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='blue' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
  .custom-control-input:checked~.custom-control-label::before {
    color: $secundary-color;
    border-color: $secundary-color;
    background-color: $white;
  }
  .form-control.is-valid, .was-validated .form-control:valid {
    border: solid $form-input-border;
    border-width: 1px;
  }
  .btn{
    width: 100%;
  }
  label{
    font-size: 14px;
    color:$txt-gray;
    margin: 5px auto;
    line-height: 15px;
    a{
      color: $secundary-color;
    }
    @media (min-width: 768px){
      font-size: 12px;
      // margin-top: 10px;
      line-height: 20px;
      // margin: 10px auto;
      color: $form-gray;
      font-weight: $medium;
    }
  }
  .form-control{
    font-family: $roboto;
    color:$form-gray;
    font-size: 15px;
    @media (min-width: 768px){
      font-size: 12px;
      color:$form-gray;
    }
  }
  ::placeholder {
    color:$form-placeholder;
  }
  input{
    // font-weight: $medium;
    height: 35px;
    font-family: $roboto;
    color:$form-gray;
    font-size: 15px;
    margin-bottom: 5px;
    @media (min-width: 768px){
      font-size: 12px;
      color:$form-gray;
    }
    &:focus{
      border-color:$secundary-color; 
      box-shadow: 0 0 0 0.2rem rgba($secundary-color,.4);
    }
  }
  .custom-checkbox{
    // text-align: center;
    @media (min-width: 768px){
      margin-left: 10px;
      width: 97%;
      font-size: 14px;
      margin-top: 10px;
    }
    // label{
    //   line-height: 15px;
    // }
  }
  .btn{
    @media (min-width: 768px){
      margin-top:20px; 
    }
  }
}
.form-control{
  &:focus{
    border-color:$secundary-color; 
    box-shadow: 0 0 0 0.2rem rgba($secundary-color,.4);
  }
}
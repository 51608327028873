/* VARIABLES */
$main-color: #08478E;
$secundary-color:#00CC99;
$alternative-color: #F9B550;
$primary-color: #632678;
$white: #FFFFFF;
$black: #000;
$gray: #54555A;
$gray1: #767579;
$gray2: #8D8D8D;
$gray-light: #B2B2B2;
$gray-super-light: #F5F5F5;
$gray-super-light2: #E5E5E5;
$red: #BA2F7D;
$blue-light: #00CCFF;
$blue-dark: #0D6DB5;

$down: #B80A49;
$medium-low: #E0A141;
$medium: #f4de3a;
$medio:#f4de3a;
$medium-high: #C8C737;
$high: #128D3D;

$deep-blue: #08478E;
$bright-blue: #406EB3;
$bg-dark: hsla(219, 3%, 34%, 1);
$bg-light: hsla(240, 30%, 96%, 1);
$bg-mid: hsla(255, 1%, 46%, 1);
$dark-gray: hsla(219, 3%, 34%, 1);
$light-gray: hsla(0, 0%, 80%, 1);
$form-gray:hsla(0, 0%, 50%, 1);
$form-placeholder:hsla(0, 0%, 69%, 1);
$form-input-border: hsla(210, 14%, 83%, 1);
$error:hsla(318, 66%, 40%, 1);
$aprove: #3F9B53;

$title: #0B0B0B;
$txt-gray: hsla(230, 3%, 34%, 1);
$light-gray: hsla(0, 0%, 81%, 1);
$midc: #632678;
$main-bg: #f2f2f8;